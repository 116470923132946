<template>
  <div class="routes-customers-new module-customers-and-subscriptions">
    <div class="content">

      <div v-if="stepFlow === 'success'">
        <box-success :customer-name="CustomerName"/>
      </div>

      <div v-else>
        <div class="row text-center">
          <h2>{{ $t("components.customers-and-subscriptions.customers.actions.new.main-title") }}</h2>
        </div>

        <div class="alert alert-warning text-center" role="alert">
          <small class="error-label"
                 v-html="$t('components.customers-and-subscriptions.customers.actions.integrations-warning')" />
        </div>

        <hr>

        <div class="customer-type-boxes">
          <div class="row d-flex justify-content-center">
            <div class="col-xs-12 col-sm-4">
              <div class="ui-customer-type-box individual-person-box text-center"
                   @click="changeCustomerType('individual-person-box')"
                   :class="{ active: customerTypeBoxPerson }">
                <i class="fas fa-fw fa-check"/>
                <span>{{ $t("components.customers-and-subscriptions.customers.actions.new.individual-person") }}</span>
              </div>
            </div>
            <div class="col-xs-12 col-sm-4">
              <div class="ui-customer-type-box legal-entity-box text-center"
                   @click="changeCustomerType('legal-entity-box')"
                   :class="{ active: customerTypeBoxEntity }">
                <i class="fas fa-fw fa-check"/>
                <span>{{ $t("components.customers-and-subscriptions.customers.actions.new.legal-entity") }}</span>
              </div>
            </div>
          </div>
        </div>

        <hr>

        <div v-if="this.customerTypeBoxPerson">
          <form-new-individual-customer />
        </div>

        <div v-if="this.customerTypeBoxEntity">
          <form-new-entity-customer />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import BoxSuccess from "./box-success";
import FormNewIndividualCustomer from "./form-new-individual-customer";
import FormNewEntityCustomer from "./form-new-entity-customer";

export default {
  components: {
    BoxSuccess,
    FormNewIndividualCustomer,
    FormNewEntityCustomer
  },

  data: function () {
    return {
      customerTypeBoxPerson: true,
      customerTypeBoxEntity: false,
      stepFlow: null
    };
  },

  methods: {
    changeCustomerType (customerTypeBox) {
      if (customerTypeBox === "individual-person-box") {
        this.customerTypeBoxPerson = true;
        this.customerTypeBoxEntity = false;
      }

      if (customerTypeBox === "legal-entity-box") {
        this.customerTypeBoxPerson = false;
        this.customerTypeBoxEntity = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~styles/framework/variables";
@import "~styles/framework/mixins";

.routes-customers-new {
  hr {
    &.strong {
      border-top-width: 4px;
    }
  }

  .content {
    width: 1170px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 1050px) {
    .content {
      width: 970px;
    }
  }

  @media (max-width: 1050px) {
    .content {
      width: auto;
    }
  }
}
</style>
